// vendors
import React from "react"

const IllustrationCirclePointer = ({
  backgroundColor = "#fff",
  strokeColor = "#003439",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.51 90.51" {...props}>
    <circle cx="45.25" cy="45.25" r="45.25" fill={backgroundColor} />
    <g
      stroke={strokeColor}
      fill="none"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.18"
    >
      <path d="M31.12,42.79c0,5.12,3.82,10.28,7,13.71a54.41,54.41,0,0,0,7,6.25l.13.1.14-.1a54.84,54.84,0,0,0,7-6.29c3.2-3.43,7-8.59,7-13.67a14.14,14.14,0,0,0-28.27,0Z" />
      <line x1="49.62" y1="38.59" x2="40.87" y2="47.35" />
      <line x1="49.62" y1="38.59" x2="40.87" y2="47.35" />
      <line x1="49.62" y1="47.35" x2="40.87" y2="38.59" />
    </g>
  </svg>
)

export default IllustrationCirclePointer
