// vendors
import React from "react"

const IllustrationFace = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.09 94.06" {...props}>
    <circle cx="48.88" cy="48.8" r="45.25" fill="#fff" />

    <path d="M33.41,65.19a15.65,15.65,0,0,0,31.3,0Z" fill="#eae463" />

    <circle cx="27.68" cy="30.49" r="20.87" fill="#eae463" />
    <circle cx="25.57" cy="34.82" r="5.94" fill="#fff" />
    <circle cx="27.25" cy="34.04" r="1.52" fill="#003439" />

    <circle cx="81.22" cy="30.49" r="20.87" fill="#eae463" />
    <circle cx="78.73" cy="34.82" r="5.94" fill="#fff" />
    <circle cx="80.34" cy="34.04" r="1.52" fill="#003439" />

    <g
      fill="none"
      stroke="#003439"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.18"
    >
      <path d="M7.11,52.32c5.7,5.71,15.71,7.21,23.1,7.46A86.17,86.17,0,0,0,45,59l.26,0,0-.26a87.39,87.39,0,0,0,.78-14.8c-.27-7.4-1.77-17.41-7.43-23.07A22.29,22.29,0,0,0,7.11,52.32Z" />
      <circle cx="22.57" cy="36.26" r="9.55" />
      <circle cx="49.11" cy="64.85" r="6.52" />
      <g>
        <path d="M91.13,52.32C85.43,58,75.42,59.53,68,59.78A86.28,86.28,0,0,1,53.26,59l-.25,0,0-.26a86.18,86.18,0,0,1-.78-14.8c.26-7.4,1.76-17.41,7.42-23.07A22.29,22.29,0,1,1,91.13,52.32Z" />
        <circle cx="75.67" cy="36.26" r="9.55" />
      </g>
      <path d="M56.83,74a14.17,14.17,0,0,0-16-.29" />
      <line x1="14.54" y1="5.17" x2="30.32" y2="0.59" />
      <line x1="83.51" y1="5.17" x2="67.72" y2="0.59" />
    </g>

    <g fill="#003439">
      <circle cx="18.98" cy="66.24" r="0.99" />
      <circle cx="18.98" cy="73.4" r="0.99" />
      <circle cx="26.34" cy="66.24" r="0.99" />
      <circle cx="78.92" cy="66.24" r="0.99" />
      <circle cx="78.92" cy="73.4" r="0.99" />
      <circle cx="71.56" cy="66.24" r="0.99" />
    </g>
  </svg>
)

export default IllustrationFace
