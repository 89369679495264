// vendors
import React from "react"
import VisuallyHidden from "@reach/visually-hidden"

// components
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import css from "@emotion/css"
import { fontSizes, colors } from "../styles/variables"
import Button from "../components/Button/Button"
import wrapper from "../utils/wrapper"
import mediaQuery from "../utils/media-query"
import IllustrationCirclePointer from "../images/IllustrationCirclePointer"
import IllustrationFace from "../images/IllustrationFace"

const breakpoint = 1024

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page introuvable" />
    <VisuallyHidden>
      <h1>Erreur 404</h1>
    </VisuallyHidden>

    <div
      css={css`
        margin-top: ${fontSizes[9] / fontSizes[0]}em;
        ${mediaQuery.greaterThen(breakpoint)} {
          ${wrapper.bolt()}
          ${wrapper.paddingLeft()}
          margin-bottom: ${fontSizes[9] / fontSizes[0]}em;
        }
      `}
    >
      <div
        css={css`
          background-color: ${colors.offWhite};
          display: flex;
          overflow: hidden;
          position: relative;
          justify-content: flex-start;

          ${mediaQuery.lessThen(breakpoint)} {
            flex-flow: column;
            ${wrapper.bolt("padding")}
          }
        `}
      >
        <div
          css={css`
            margin: ${fontSizes[9] / fontSizes[0]}em 0;
            max-width: 70ch;

            ${mediaQuery.greaterThen(breakpoint)} {
              max-width: 510px;
              order: 1;
            }
          `}
        >
          <p
            css={css`
              font-size: ${fontSizes[7] / fontSizes[1]}em;
              line-height: ${56 / fontSizes[7]};
              font-weight: 900;
              margin-bottom: 2rem;

              ${mediaQuery.greaterThen(breakpoint)} {
                max-width: 490px;
              }
            `}
          >
            Désolé.
            <br />
            Nous ne pouvons trouver la page que vous cherchez.
          </p>

          <p
            css={css`
              margin-bottom: 2rem;
            `}
          >
            Erreur 404 — Il est possible que cette page ait été déplacée,
            renommée ou supprimée.
          </p>

          <div>
            <Button tag="link" to="/" full>
              Page d'accueil
            </Button>
          </div>
        </div>

        <div
          css={css`
            text-align: center;

            ${mediaQuery.lessThen(breakpoint)} {
              margin-bottom: ${fontSizes[9] / fontSizes[0]}em;
            }
            ${mediaQuery.greaterThen(breakpoint)} {
              order: 0;
              position: relative;
              margin-top: ${fontSizes[9] / fontSizes[0]}em;
              margin-right: ${(86 / 1920) * 100}vw;

              max-width: 562px;
              width: ${(562 / 1920) * 100}vw;
            }
          `}
        >
          <IllustrationFace
            css={css`
              max-width: 295px;
              /* width: ${(295 / 1920) * 100}vw; */

              ${mediaQuery.greaterThen(breakpoint)} {
                position: absolute;
                top: 0;
                right: 0;
              }
            `}
          />
          <IllustrationCirclePointer
            css={css`
              max-width: 260px;
              width: ${(260 / 1920) * 100}vw;

              ${mediaQuery.lessThen(breakpoint)} {
                display: none;
              }
              ${mediaQuery.greaterThen(breakpoint)} {
                position: absolute;
                /* top: ${(160 / 1920) * 100}vw; */
                /* top: calc(50% + ${(-107.5 / 1920) * 100}vw); */
                bottom: ${(115 / 1920) * 100}vw;
                left: ${(-36 / 1920) * 100}vw;
              }

              ${mediaQuery.greaterThen(1920)} {
                position: absolute;
                top: 160px;
                left: -36px;
              }
            `}
          />
          <IllustrationCirclePointer
            css={css`
              max-width: 260px;
              width: ${(260 / 1920) * 100}vw;

              ${mediaQuery.lessThen(breakpoint)} {
                display: none;
              }
              ${mediaQuery.greaterThen(breakpoint)} {
                position: absolute;
                bottom: ${(-61 / 1920) * 100}vw;
                /* top: ${(335 / 1920) * 100}vw; */
                left: ${(239 / 1920) * 100}vw;
              }
              ${mediaQuery.greaterThen(1920)} {
                position: absolute;
                /* top: 335px; */
                bottom: -61px;
                left: 239px;
              }
            `}
            backgroundColor={colors.dullYellow}
            strokeColor={colors.white}
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
